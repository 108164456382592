import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`Testing`}</h4>
    <p>{`Chips undergo tests requiring a range of specialized equipment, including system-on-a-chip test tools, linear and discrete devices, burn-in tools, and handlers and probers.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      